import React from "react";
import SEO from "../../components/common/seo.js";
import Layout from "../../components/common/layout";
import Navigation from "../../components/navigation/navigation";
import Header from "../../components/sections/header";
import Map from "../../components/sections/map";
import Contact from "../../components/sections/contact";
import { Dot, Container } from "../../components/global";
import styled from "styled-components";

const FuzjePrzejecia = () => (
  <Layout>
    <SEO
      title='Fuzje i Przejęcia'
      description='Kompleksowa pomoc prawna w przeprowadzaniu procesu połączenia, podziału lub przekształcenia spółki ze szczególnym uwzględnieniem uwarunkowań podatkowych i organizacyjnych. Fachowe doradztwo w wyborze najbardziej optymalnej formy prowadzenia działalności.'
    />
    <Navigation />
    <Header />
    <Article id='page'>
      <Title>Fuzje i Przejęcia</Title>

      <Container>
        <p>
          Kompleksowa pomoc prawna w przeprowadzaniu procesu połączenia,
          podziału lub przekształcenia spółki ze szczególnym uwzględnieniem
          uwarunkowań podatkowych i organizacyjnych. Fachowe doradztwo w wyborze
          najbardziej optymalnej formy prowadzenia działalności.
        </p>
        <List>
          <Dot>due diligence;</Dot>
          <Dot>
            wybór optymalnego modelu transakcji biorąc pod rozwagę kwestie
            podatkowe i organizacyjne (w tym fakt występowania grup kapitałowych
            i potrzebę ich restrukturyzacji);
          </Dot>
          <Dot>pozyskiwanie finansowania transakcji;</Dot>
          <Dot>listy intencyjne, oferty przejęcia i umowy inwestycyjne;</Dot>
          <Dot>
            umowy sprzedaży udziałów, akcji oraz przedsiębiorstw lub
            zorganizowanych części przedsiębiorstw (SPA);
          </Dot>
          <Dot>
            transakcje wykupów lewarowanych (LBO) oraz wykupów menedżerskich
            (MBO, MBI).
          </Dot>
        </List>
      </Container>
    </Article>

    {/* <Contact /> */}
  </Layout>
);

export default FuzjePrzejecia;

const Title = styled.h4`
  margin-top: 75px;
`;

const List = styled.ul`
  padding: 40px 0px 150px;
  li {
    list-style-position: inside;
    text-indent: -1.5em;
    padding-left: 1.5em;
    line-height: 1.5;
  }
`;
const Article = styled.article`
  padding-top: 20px;
  /* border: 1px solid red; */
`;
